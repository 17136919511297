import { LanguageContentType } from '@lib/types/geo-lp'

export const URDU_CONTENT: LanguageContentType = {
  bookTrialClassCTA: 'فری کلاس شیڈول کریں',
  heroFold: {
    formatHeading() {
      return `پاکستان میں بچوں کے لئے بہترین کوڈنگ کلاسیں`
    },
    subHeading: <>پہلی سے بارہویں جماعت کے لئے کوڈنگ کورس</>,
    stats: [
      {
        statNumber: '٢٠+ ملک',
      },
      {
        statNumber: '+١،٥٠،٠٠٠ طالب علم',
      },
    ],

    ratings: [
      {
        ratingSubHeading: '٤.٨ نصاب ریٹنگ',
      },
      {
        ratingSubHeading: '٤.٩ اوسط والدین اور طالب علم ریٹنگ',
      },
    ],
    form: {
      gradeFieldPlaceholder: 'جماعت چنیں',
      phoneFieldLabel: 'اپنا فون نمبر درج کریں',
      ctaText: 'فری کلاس شیڈول کریں',
      hasLaptopField: {
        label: 'کیا آپ کے پاس کلاس کے لیے لیپ ٹاپ/پی سی ہے؟',
        option1: 'جی ہاں',
        option2: 'نہیں',
      },
      termsAndConditions:
        'سائیں اپ کر کے آپ ہماری سروس کی شرائط اور پالیسی سے اتفاق کرتے ہیں. آپ اس بات سے اتفاق رکھتے ہیں کی آپ کے پاس آپ کے والدین کی رضا مندی ہے . ضروری اپ ڈیٹ وہاٹسپپ کے ذریعہ بھیجی جائیں گی.',
    },
  },
  partnersFold: {
    builtBySectionHeading: 'تعمیر کردہ از سابق طلباء',
    partnershipSectionHeading: 'سے شراکت داری میں',
    stemSectionHeading: 'کے ذریعہ تسلیم شدہ STEM.Org',
    backedBySectionHeading: 'پشت پناہی کے ساتھ',
  },
  learnToCodeFold: {
    heading: {
      normalText: 'اور سرٹیفکٹ پائیں',
      underlinedText: 'کوڈنگ سیکھیں',
    },
    subHeading:
      'ممتاز عالمی اداروں سے سرٹیفکیٹ حاصل کریں اور فخر کے ساتھ دنیا کو دکھائیں کہ آپ کوڈ کر سکتے ہیں۔',
  },
  testimonialsFold: {
    heading: {
      normalText: 'کرتے ہیں یہ یہاں ہے۔',
      underlinedText: 'ہمارے طلباء کوڈنگل کیوں پسند',
    },
    contents: [
      {
        name: 'فائزہ خالد',
        imageName: 'p-faiza',
        subheading: 'کوڈنگل والدین',
        content:
          'کوڈنگل اساتذہ جانتے ہیں کہ ہر بچہ مختلف ہوتا ہے اور سیکھنے کے تجربے کو بچے کے مطابق مکمل طور پر ذاتی بناتا ہے۔',
      },
      {
        name: 'بابر',
        imageName: 'babar',
        subheading: 'گریڈ 5، کوڈنگل طالب علم',
        content:
          'کوڈنگل فری وسائل کے مقابلے کوڈ سیکھنے کا ایک بہتر تجربہ پیش کرتا ہے اور مجھے تیزی سے سیکھنے میں مدد کرتا ہے',
      },
      {
        name: 'عادل تنویر',
        imageName: 'p-adil-tanveer',
        subheading: 'کوڈنگل والدین',
        content:
          'کوڈنگل کا کریکولم سوچ سمجھ کر بنایا گیا ہے۔ اساتذہ اعلیٰ تعلیم یافتہ ہیں۔ کسٹمر سروس بہترین ہے-',
      },
      {
        name: 'بلال عادل',
        imageName: 'm-bilal-adil',
        subheading: 'گریڈ 4، کوڈنگل طالب علم',
        content:
          'کوڈنگل کا  تفصیلی ایپ ڈویلپمنٹ کورس کوڈ سیکھنے اور  سمجھنے کے لیے بہترین اور آسان  ہے۔ آپ کا شکریہ، کوڈنگل',
      },
    ],
    reviewLinkText: 'مزید جائزے دیکھیں',
  },
  whyCodingalFold: {
    heading: {
      normalText: ' کیوں منتخب کریں',
      underlinedText: 'کوڈنگل',
    },
    contents: [
      {
        heading: 'ماہر انسٹرکتروں سے ہدایت یافتہ',
        imgLabel: 'guided-by-expert-instructors',
        subHeading:
          'آل سٹار کمپیوٹر سائنس ٹیچروں کے ساتھ ون آن ون سیشنز جو آپ کے کوڈنگ سیکھنے کے سفر میں آپ کا رہ نما ہونے کے لئے تربیت یافتہ ہیں',
      },
      {
        heading: 'سب سے سائنسی نصاب',
        imgLabel: 'powered-by-scientific-curriculum',
        subHeading: `دنیا کا اکلوتا کوڈنگ نصاب جو BIDE، STEAM اور Bloom's Taxonomy کی بنیاد پر بنایا گیا ہے تاکہ بچوںکو سائنسی طرائق سے کوڈنگ سکھائی جا سکے`,
      },
      {
        heading: 'تدریس کی رفتار کو آپ کے پڑھنے کی طریقے کے مد مقابل ہوگی',
        imgLabel: 'pace-of-instruction-that-matches-your-learning-style',
        subHeading:
          'آپ کے پڑھنے کی مطابق تدریس کی رفتار تاکہ آپ کورس سے مزید فائدہ اٹھا سکیں',
      },
    ],
  },
  footer: {
    supprotHeading: 'سوال ہیں؟ ہمیں لکھیں',
    copyRights: '© ٢٠٢٢ codingal تمام حقوق محفوظ',
  },
}
