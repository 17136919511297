import {
  BAHASA_CONTENT,
  BANGLA_CONTENT,
  ENGLISH_CONTENT,
  URDU_CONTENT,
} from '@lib/constants/geo-contents'
import { LinguiJSMessageDescriptor, UnionizeObjValues } from '@lib/types/common'
import { LanguageContentType } from '@lib/types/geo-lp'

export const IMAGE_PATH = '/images/book-trial-bd-v2'
export const PARTNERS_IMAGE_PATH = `${IMAGE_PATH}/partners`

export const HEADER_TEXT_GRADIENT = {
  background: 'linear-gradient(90deg,#BF3240,#7E36D9 93.67%)',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
}
export const GEO_LANDING_PAGES = [
  '/**/book-trial-bd-v2/**',
  '/**/book-trial-id-v2/**',
  '/**/book-trial-vt-v2/**',
  '/**/book-trial-ph-v2/**',
  '/**/book-trial-ng-v2/**',
  '/**/book-trial-ke-v2/**',
  '/**/book-trial-sg-v2/**',
]

type TesimonialType = {
  author: string
  grade?: number
  role: string
  content: string
}
export const TESTIMONIALS: TesimonialType[] = [
  {
    author: 'ray',
    grade: 3,
    role: 'Codingal Student',
    content:
      "I love learning with Codingal. It's always fun and the teacher is nice and kind.",
  },
  {
    author: 'billie',
    grade: 5,
    role: 'Codingal Student',
    content:
      "Codingal classes are so much fun. I've started to really enjoy creating things with code.",
  },
  {
    author: 'saaransh',
    grade: 4,
    role: 'Codingal Student',
    content:
      "I now have two apps published on the Google Play Store. I'm glad to be learning coding with Codingal.",
  },
  {
    author: 'ikumi',
    role: 'Codingal Parent',
    content:
      "Codingal's well-structured courses have made coding fun for my son. They're the best and the quickest.",
  },
]

export const COUNTRIES = {
  bd: 'Bangladesh',
  pk: 'Pakistan',
  lk: 'Sri Lanka',
  bh: 'Bahrain',
  ae: 'United Arab Emirates',
  sa: 'Saudi Arabia',
  om: 'Oman',
  qa: 'Qatar',
  eg: 'Egypt',
  za: 'South Africa',
  ph: 'Philippines',
  id: 'Indonesia',
  ke: 'Kenya',
  vn: 'Vietnam',
  sg: 'Singapore',
  in: 'India',
  br: 'Brazil',
  mx: 'Mexico',
  np: 'Nepal',
  bt: 'Bhutan',
  ru: 'Russia',
  tr: 'Turkey',
  th: 'Thailand',
  ng: 'Nigeria',
  zm: 'Zambia',
  cd: 'Democratic Republic of Congo',
  tz: 'Tanzania',
  ug: 'Uganda',
  ma: 'Morocco',
  et: 'Ethiopia',
  gh: 'Ghana',
  ye: 'Yemen',
  jo: 'Jordan',
  my: 'Malaysia',
  dz: 'Algeria',
  cu: 'Cuba',
  zw: 'Zimbabwe',
  rw: 'Rwanda',
  iq: 'Iraq',
  ge: 'Georgia',
  fj: 'Fiji',
  kh: 'Cambodia',
  tl: 'East Timor',
  la: 'Laos',
  mz: 'Mozambique',
  sd: 'Sudan',
  ml: 'Mali',
  mu: 'Mauritius',
  tn: 'Tunisia',
  ly: 'Libya',
  lb: 'Lebanon',
  pl: 'Poland',
  pt: 'Portugal',
  gr: 'Greece',
  gb: 'United Kingdom',
  ca: 'Canada',
  nl: 'Netherlands',
  au: 'Australia',
  de: 'Germany',
  fr: 'France',
  hu: 'Hungary',
  be: 'Belgium',
  it: 'Italy',
  es: 'Spain',
  ro: 'Romania',
  sc: 'Seychelles',
  bw: 'Botswana',
} as const

type CountryType = UnionizeObjValues<typeof COUNTRIES>

export const getCodeByCountry = (country: CountryType) =>
  Object.keys(COUNTRIES).find((code) => COUNTRIES[code] === country)

// GEO LOCALAZATION CONFIGS
export const TARGATED_REGIONS = {
  AFRICA: 'africa',
  AMERICA: 'america',
  INDIA: 'india',
} as const

export const LANGUAGES = {
  ENGLISH: 'ENGLISH',
  BAHASA: 'BAHASA',
  BANGLA: 'BANGLA',
  URDU: 'URDU',
} as const
export type LanguageType = UnionizeObjValues<typeof LANGUAGES>

type GeoContentType = Record<
  LanguageType,
  LanguageContentType<string | LinguiJSMessageDescriptor>
>
export const GEO_CONTENT: GeoContentType = {
  ENGLISH: ENGLISH_CONTENT as LanguageContentType<LinguiJSMessageDescriptor>,
  BAHASA: BAHASA_CONTENT,
  BANGLA: BANGLA_CONTENT,
  URDU: URDU_CONTENT,
}

export const COUNTRY_CODES = {
  IN: 'IN',
  PK: 'PK',
  US: 'US',
  NP: 'NP',
  CA: 'CA',
  JP: 'JP',
}

type PhysicalAddress = {
  name?: string
  country: string
  ph: string
  address: string
}

const US_ADDRESS: PhysicalAddress = {
  country: 'USA',
  ph: '+1 (510) 361-9534',
  address: '1111B S Governors Ave #6017, Dover, Delaware, 19904, United States',
}

export const COUNTRIES_WITH_PHYSICAL_ADDRESS: Record<
  string,
  PhysicalAddress
> = {
  US: US_ADDRESS,
  CA: US_ADDRESS,
}

export const LP_COUNTRY_CODES = Object.keys(COUNTRIES)
