import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { Trans } from '@lingui/macro'

type Props = {
  msg: string | LinguiJSMessageDescriptor
}

/**
 * Trans component which is to be used with defineMessage
 */
const MsgTrans = ({ msg: message }: Props) => {
  if (!message) return null
  if (typeof message === 'string') return <>{message}</>
  return (
    <Trans
      id={message.id}
      values={message.values}
      comment={message.comment}
      context={message.context}
    />
  )
}

export default MsgTrans
