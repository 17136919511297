import { LanguageContentType } from '@lib/types/geo-lp'

export const BAHASA_CONTENT: LanguageContentType = {
  bookTrialClassCTA: 'Daftar kelas GRATIS',
  heroFold: {
    formatHeading(country) {
      return `Kelas coding online terbaik untuk anak-anak di ${country}`
    },
    subHeading: (
      <>
        Kursus coding untuk <span className="font-600">kelas 1-12</span>
      </>
    ),
    stats: [
      {
        statNumber: '500,000+',
        statSubHeading: 'Siswa',
      },
      {
        statNumber: '70+',
        statSubHeading: 'Negara',
      },
    ],
    ratings: [
      {
        ratingNumber: 4.9,
        ratingSubHeading: 'rating rata-rata dari siswa dan orang tua',
      },
      {
        ratingNumber: 4.8,
        ratingSubHeading: 'rating kurikulum',
      },
    ],
    form: {
      gradeFieldPlaceholder: 'Pilih kelas',
      phoneFieldLabel: 'Nomor telepon (HP)',
      ctaText: 'Daftar kelas gratis',
      hasLaptopField: {
        label: 'Apakah Anda memiliki laptop/ PC untuk mengikuti kelas?',
        option1: 'Ya',
        option2: 'Tidak',
      },
    },
  },
  partnersFold: {
    builtBySectionHeading: 'Dikembangkan oleh Alumni',
    partnershipSectionHeading: 'Mitra Kerjasama',
    stemSectionHeading: 'Terakreditasi oleh STEM.org',
    backedBySectionHeading: 'Didukung oleh',
  },
  learnToCodeFold: {
    heading: {
      normalText: 'Belajar coding dan',
      underlinedText: 'dapatkan sertifikat',
    },
    subHeading:
      'Dapatkan sertifikat dari institusi global bergengsi dan dengan bangga tunjukkan kepada dunia kemampuan coding Anda',
  },
  testimonialsFold: {
    heading: {
      normalText: 'Siswa dan orangtua',
      underlinedText: 'menyukai Codingal',
    },
    contents: [
      {
        name: 'Rivaan',
        imageName: 'Rivaan',
        subheading: 'Kelas 3 - Siswa Codingal',
        content:
          'Kelas coding bersama Codingal sangat interaktif dan proyek yang saya kerjakan juga membantu saya belajar lebih baik di sekolah.',
      },
      {
        name: 'Azka',
        imageName: 'Azka',
        subheading: 'Kelas 3 - Siswa Codingal',
        content:
          'Kelas coding yang bagus sekali dengan modul dan dasbor yang lengkap. Belajar coding sangat menyenangkan.',
      },
      {
        name: 'Debashis',
        imageName: 'Debashis',
        subheading: 'Orangtua Codingal',
        profile: 'COO - MNC Group Corporation Jakarta',
        content:
          'Anak saya sangat senang mengikuti kelas Codingal dan sangat suka menjadi bagian dari komunitas coders yang berkembang pesat.',
      },
      {
        name: 'Rossa',
        imageName: 'Rossa',
        subheading: 'Orangtua Codingal',
        profile: 'Bekerja di Sektor Publik',
        content:
          'Meskipun kami tidak terlalu fasih berbahasa Inggris, guru di Codingal dengan sabar menjelaskan secara perlahan dan sangat interaktif',
      },
    ],
    reviewLinkText: 'See more reviews',
  },
  whyCodingalFold: {
    heading: {
      normalText: 'Mengapa memilih',
      underlinedText: 'Codingal',
    },
    contents: [
      {
        heading: 'Dibimbing oleh',
        coloredHeading: 'instruktur berpengalaman',
        imgLabel: 'guided-by-expert-instructors',
        subHeading:
          'Sesi 1:1 dengan guru ilmu komputer terpilih yang telah dilatih untuk menjadi mentor yang sempurna bagi anak-anak dalam proses pembelajaran coding',
      },
      {
        heading: 'Didukung oleh',
        coloredHeading: 'kurikulum ilmiah',
        imgLabel: 'powered-by-scientific-curriculum',
        subHeading:
          'Satu-satunya kurikulum coding di dunia yang disusun berdasarkan BIDE, STEAM, dan Taksonomi Bloom dalam mengajarkan coding secara ilmiah kepada anak-anak',
      },
      {
        heading: 'Pengajaran disesuaikan',
        coloredHeading: 'dengan gaya belajar anak',
        imgLabel: 'pace-of-instruction-that-matches-your-learning-style',
        subHeading:
          'Pengajaran yang disesuaikan dengan gaya belajar anak-anak untuk memastikan hasil yang didapatkan dari kursus maksimal',
      },
    ],
  },
  footer: {
    supprotHeading:
      'Apakah Anda memiliki pertanyaan? Silakan tinggalkan kami pesan',
  },
}
